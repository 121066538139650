import {computed, inject, Injectable, signal} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {Event} from '../../event/shared/event';
import { EventApi } from '@app/event/shared/event.api';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  eventApi = inject(EventApi);
  activeEvent = computed(() => {
    if (this.events()) {
      return this.events().find(e => e.active);
    }
    return null;
  });
  events = signal<Event[]>(null);

  async initialize(): Promise<void> {
    const events = await firstValueFrom(this.eventApi.findAll());
    this.events.set(events);
  }

  setEvent(event: Event): void {
    this.events.update(events => events.map(e => {
      e.active = e.uuid === event.uuid;
      return e;
    }));
  }
}
