<section class="section section-app">
  <div class="click-listener" *ngIf="open" (click)="closeCard()"></div>
  <header class="header header-app">
    <mat-toolbar color="primary" class="header-app-toolbar">
      <span class="header-app-toolbar-item" [routerLink]="''">
        <img class="header-app-toolbar-img" src="./assets/logo.png" [alt]="title">
      </span>

      <mat-list class="header-app-toolbar-list">
        <mat-list-item class="foo" routerLinkActive="active" [routerLink]="'event'">Evenementen</mat-list-item>
        <mat-list-item routerLinkActive="active" [routerLink]="'chapter'">Hoofdstukken</mat-list-item>
        <mat-list-item routerLinkActive="active" [routerLink]="'content'">Berichten</mat-list-item>
        <mat-list-item routerLinkActive="active" [routerLink]="'participants'">Deelnemers</mat-list-item>
        <mat-list-item routerLinkActive="active" [routerLink]="'import'" *ngIf="false">Deelnemers import</mat-list-item>
        <mat-list-item routerLinkActive="active" [routerLink]="'teams'">Teams</mat-list-item>
        <mat-list-item routerLinkActive="active" [routerLink]="'sync'" *ngIf="false">Synchronizeer</mat-list-item>
        <mat-list-item routerLinkActive="active" [routerLink]="'funraisin'" *ngIf="false">Funraisin</mat-list-item>
        <mat-list-item routerLinkActive="active" [routerLink]="'pushmessages'">Pushmessages</mat-list-item>
      </mat-list>

      <span class="header-app-toolbar-spacer"></span>

      <span>
        <button mat-icon-button (click)="openCard()"><mat-icon>account_circle</mat-icon></button>
      </span>

      <mat-card appearance="outlined" class="header-app-toolbar-card" *ngIf="open">
        <mat-list class="header-app-toolbar-card-list">
          <mat-list-item class="header-app-toolbar-card-list-item username-list-item">
            <span class="username" color="primary">{{session.user.username}}</span>
          </mat-list-item>

          <mat-list-item class="header-app-toolbar-card-list-item">
            <mat-form-field>
              <mat-select [value]="activeEvent().uuid" (selectionChange)="onEventChange($event.value)">
                @for (item of events(); track item.uuid) {
                <mat-option [value]="item.uuid">
                  {{item.name}}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </mat-list-item>

          <mat-list-item class="header-app-toolbar-card-list-item">
            <button mat-button color="primary" (click)="logout()">Uitloggen</button>
          </mat-list-item>
        </mat-list>
      </mat-card>
    </mat-toolbar>
    <mat-progress-bar class="header-app-loader" mode="indeterminate" color="accent" *ngIf="loading"></mat-progress-bar>
  </header>
  <main class="main main-app" (click)="closeCard()">
    <router-outlet></router-outlet>
  </main>
  <footer class="footer footer-app">
    <span class="footer-app-item">{{activeEvent()?.name}}</span>

    <span class="footer-app-item"><mat-icon>info</mat-icon>&nbsp;AD6-Net - v{{version}}</span>
  </footer>
</section>