export const environment = {
  production: false,
  api_endpoint: '/mapi',
  kc_auth_server_url: 'https://ad6-test.flusso.nl/auth',
  kc_realm: "AD6",
  kc_ssl_required: "none",
  kc_resource: "ad6-net",
  kc_public_client: true,
  kc_confidential_port: 0
};
