import {Component, computed, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from './core/services/authentication.service';
import {Session} from './core/objects/session';
import {EventApi} from './event/shared/event.api';
import {EventService} from './core/services/event.service';
import {Subscription} from 'rxjs';
import {Event} from './event/shared/event';
import {Event as AngularEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import packageJson from '../../package.json';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Alpe d\'HuZes';
  bar: string;
  session: Session;

  activeEvent = this.eventService.activeEvent;
  events = this.eventService.events;

  loading = false;
  open = false;
  version = packageJson.version;

  private eventSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private eventApi: EventApi,
    private eventService: EventService,
    private router: Router
  ) {
    this.router.events.subscribe((event: AngularEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.session = this.authenticationService.getSession();
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

  logout() {
    this.authenticationService.logout();
  }

  onEventChange(value: any) {
    this.eventService.setEvent(value);
  }

  openCard() {
    this.open = !this.open;
  }

  closeCard() {
    if (this.open) {
      this.open = false;
    }
  }
}
