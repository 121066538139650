import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
import { Session } from '../objects/session';
import { User } from '../objects/user';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private auth: any = {};

  init(): Promise<any> {
    return new Promise((resolve, reject) => {

      const config = {
        realm: environment.kc_realm,
        url: environment.kc_auth_server_url,
        clientId: environment.kc_resource,
      } as Keycloak.KeycloakConfig;

      const keycloakAuth = new Keycloak(config);
      // const keycloakAuth = Keycloak('assets/keycloak/keycloak.json');
      keycloakAuth.init({
        onLoad: 'login-required'
      })
        .then(() => {
          this.auth.loggedIn = true;
          this.auth.keycloak = keycloakAuth;
          this.auth.logoutUrl = keycloakAuth.authServerUrl
            + '/realms/AD6/protocol/openid-connect/logout?redirect_uri='
            + document.baseURI;
          console.log(this.auth);
          resolve(void 0);
        })
        .catch(() => {
          reject();
        });
    });
  }

  async getToken(): Promise<string> {
    if (this.auth.keycloak.token) {
       await this.auth.keycloak.updateToken(60);
    } else {
      return 'Not logged in';
    }

    return this.auth.keycloak.token;
  }

  getParsedToken() {
    console.log('idToken', this.auth.keycloak.idToken);
    return this.auth.keycloak.tokenParsed;
  }

  getSession() {
    const session = new Session();
    session.user = this.createUser(this.auth.keycloak.idTokenParsed);
    return session;
  }

  private createUser(idTokenParsed): User {
    const user = new User();
    user.username = idTokenParsed.preferred_username;
    user.name = idTokenParsed.name;
    user.given_name = idTokenParsed.give_name;
    user.family_name = idTokenParsed.family_name;
    user.email = idTokenParsed.email;
    return user;
  }

  logout() {
    console.log('*** LOGOUT');
    this.auth.loggedIn = false;
    this.auth.authz = null;
    window.location.href = this.auth.logoutUrl;
  }
}
