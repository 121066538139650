import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
    path: 'chapter',
    loadChildren: () => import('./content/chapter.module').then(m => m.ChapterModule),
  },
  {
    path: 'content',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./event/event.module').then(m => m.EventModule)
  },
  {
    path: 'funraisin',
    loadChildren: () => import('./funraisin/funraisin.module').then(m => m.FunraisinModule)
  },
  {
    path: 'teams',
    loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
  },
  {
    path: 'participants',
    loadChildren: () => import('./participant/participant.module').then(m => m.ParticipantModule),
  },
  {
    path: 'pushmessages',
    loadChildren: () => import('./pushmessages/push-message.module').then(m => m.PushMessageModule),
  },
  {path: 'sync', loadChildren: () => import('./sync/sync.module').then(m => m.SyncModule)},
  {path: 'import', loadChildren: () => import('./import/import.module').then(m => m.ImportModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
