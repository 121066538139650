{
  "name": "ad6-net-app",
  "version": "1.2.1",
  "private": true,
  "scripts": {
    "ng":                     "ng",
    "start":                  "ng serve",
    "start-test":             "ng serve --configuration=test",
    "build":                  "ng build",
    "build-test":             "ng build --base-href=/net/ --configuration=test",
    "build-prod":             "ng build --base-href=/net/ --configuration=production",
    "build-docker":           "ng build --base-href=/net/ --configuration=docker",
    "build-cloud":            "ng build --configuration=cloud",
    "test": "ng test",
    "e2e": "ng e2e",
    "lint": "ng lint",
    "build:ci:development":   "ng build --base-href=/net/ --configuration=next",
    "build:ci:test":          "ng build --base-href=/net/ --configuration=test",
    "build:ci:production":    "ng build --base-href=/net/ --configuration=production",
    "lint:ci": "ng lint"
  },
  "dependencies": {
    "@angular/animations": "18.2.3",
    "@angular/cdk": "^18.2.3",
    "@angular/common": "^18.2.3",
    "@angular/compiler": "^18.2.3",
    "@angular/core": "^18.2.3",
    "@angular/forms": "^18.2.3",
    "@angular/material": "^18.2.3",
    "@angular/platform-browser": "^18.2.3",
    "@angular/platform-browser-dynamic": "^18.2.3",
    "@angular/router": "^18.2.3",
    "@kolkov/angular-editor": "^2.1.0",
    "@stomp/rx-stomp": "^2.0.0",
    "@swimlane/ngx-charts": "^20.5.0",
    "core-js": "^3.38.1",
    "keycloak-js": "^25.0.4",
    "rxjs": "~7.8.1",
    "toastr": "^2.1.4",
    "tslib": "^2.7.0",
    "zone.js": "~0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.3",
    "@angular/cli": "^18.2.3",
    "@angular/compiler-cli": "^18.2.3",
    "@angular/language-service": "^18.2.3",
    "@types/node": "^20.14.2",
    "ts-node": "~10.9.2",
    "tslint": "~6.1.3",
    "typescript": "5.4"
  },
  "engines": {
    "node": ">=20.14.0",
    "npm": ">=10.7.0"
  }
}
