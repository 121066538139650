import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EventService} from '../services/event.service';

@Injectable({
  providedIn: 'root'
})
export class EventInterceptor implements HttpInterceptor {

  eventService = inject(EventService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let eventUuid = '';
    if (this.eventService.activeEvent()) {
      eventUuid = this.eventService.activeEvent().uuid;
    }

    req = req.clone({
      setHeaders: {
        'X-Event': eventUuid
      }
    });
    return next.handle(req);
  }
}
