import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseModel } from './base-model';
import { ListFilter } from '../objects/list-filter';
import { ListResult } from '../objects/list-result';
import { Pagination } from '../objects/pagination';
import { ApiUtils } from '../utils/api.utils';
import { SearchResult } from './objects/search/search-result';
import { SearchRequest } from './objects/search/search-request';

export abstract class BaseApi<M extends BaseModel> {
    protected endpoint: string;

    protected constructor(protected httpClient: HttpClient, path: string) {
        this.endpoint = `${environment.api_endpoint}/${path}`;
    }

    abstract create(): M;

    public findAll(): Observable<M[]> {
        return this.httpClient.get<M[]>(this.endpoint);
    }

    public findByUuid(uuid: String): Observable<M> {
        return this.httpClient.get<M>(`${this.endpoint}/${uuid}`).pipe(map(value => {
            return Object.assign(this.create(), value);
        }));
    }

    public findByRequest(searchRequest: SearchRequest): Observable<SearchResult<M>> {
        const params = {
            page: searchRequest.pageIndex.toString(),
            size: searchRequest.pageSize.toString(),
            sort: searchRequest.sort.join(','),
        };

        const queryParams = Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");
        return this.httpClient.post<SearchResult<M>>(`${this.endpoint}/find?${queryParams}`, searchRequest);
    }

    public save(model: M): Observable<String> {
        return this.httpClient.post<String>(`${this.endpoint}`, model).pipe(map(value => {
            return value;
        }));
    }

    public update(model: M): Observable<String> {
        return this.httpClient.put(`${this.endpoint}/${model.uuid}`, model, { responseType: 'text' });
    }

    public delete(uuid: String): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.endpoint}/${uuid}`);
    }
}
