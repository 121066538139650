import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Event} from './event';
import {BaseApi} from '../../core/base/base-api';
import {Observable} from 'rxjs';
import {SubEvent} from './sub-event';
import {EventSetting} from './event-setting';

@Injectable({
  providedIn: 'root'
})
export class EventApi extends BaseApi<Event> {
  constructor(httpClient: HttpClient) {
    super(httpClient, 'v1/events');
  }

  public getEventPeriods(uuid: string): Observable<SubEvent[]> {
    return this.httpClient.get<SubEvent[]>(`${this.endpoint}/${uuid}/subevents`);
  }

  public getEventSettings(uuid: string): Observable<EventSetting[]> {
    return this.httpClient.get<EventSetting[]>(`${this.endpoint}/${uuid}/settings`);
  }

  create(): Event {
    return new Event();
  }
}
