import {Injectable} from '@angular/core';

import * as toastr from 'toastr';

/**
 * Do not forget to add @import "~toastr/toastr"; to your sass styles
 *
 * Powered by: https://github.com/CodeSeven/toastr look there for options
 */

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  private defaultOptions = {
    positionClass: 'toast-bottom-center'
  };

  constructor() {
  }

  public info(message: string, title: string, options?: any): void {
    toastr.info(message, title,  { ...this.defaultOptions, ...options});
  }

  public error(message: string, title: string, options?: any): void {
    toastr.error(message, title,  { ...this.defaultOptions, ...options});
  }

  public warning(message: string, title: string, options?: any): void {
    toastr.warning(message, title,  { ...this.defaultOptions, ...options});
  }

  public remove(): void {
    toastr.remove();
  }

  public clear(): void {
    toastr.clear();
  }

  public success(message: string, title: string, options?: any) {
    toastr.success(message, title, { ...this.defaultOptions, ...options});
  }
}
