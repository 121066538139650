import {SubEvent} from './sub-event';
import {EventSetting} from './event-setting';

export class Event {
  uuid: string;
  code: string;
  name: string;
  latitude: number;
  longitude: number;
  active: boolean;
  subEvents: SubEvent[];
  settings: EventSetting[];
}
