import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from './core/services/authentication.service';
import { TokenInterceptor } from './core/interceptor/token.interceptor';
import { HttpErrorInterceptor } from './core/interceptor/http-error.interceptor';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { EventInterceptor } from './core/interceptor/event.interceptor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { EventService } from './core/services/event.service';

registerLocaleData(localeNL);

export const initializeApp = (authenticationService: AuthenticationService, eventService: EventService): (() => Promise<void>) => {
  return async () => {
    await authenticationService.init();
    await eventService.initialize();
  };
};

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatListModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCardModule,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthenticationService, EventService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EventInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'nl' },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
